import styles from './Lightbox.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { IGalleryItem } from './IGalleryItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';

export interface ILightboxParams {
  image: IGalleryItem;
  displayPrev: boolean;
  displayNext: boolean;
  prev(): void;
  next(): void;
  close(): void;
}

export default function Lightbox({ image, displayPrev, displayNext, prev, next, close }: ILightboxParams) {
  const handleKeydown = e => {
    if (e.keyCode === 27) {
      // Esc key
      return close();
    }

    if (e.keyCode === 37 && displayPrev) {
      // Left arrow key
      return prev();
    }

    if (e.keyCode === 39 && displayNext) {
      // Right arrow key
      return next();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, false);

    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [ image ]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="lightbox"
      >
        <div className={styles.lightbox}>
          <div className={styles.overlay} onClick={close} />

          <div className={styles.loading}>Loading...</div>

          <div className={styles.imageContainer}>
            <AnimatePresence>
              <motion.img
                layout={false}
                key={image.full}
                src={image.full}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              />
            </AnimatePresence>
          </div>


          {displayPrev && (
            <div onClick={prev} className={[ styles.button, styles.prev ].join(' ')}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
            </div>
          )}

          {displayNext && (
            <div onClick={next} className={[ styles.button, styles.next ].join(' ')}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
          )}

          <div onClick={close} className={[ styles.button, styles.close ].join(' ')}>
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
