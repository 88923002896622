import styles from './Gallery.module.scss';
import { useState, useEffect } from 'react';
import Lightbox from './Lightbox';

import { IGalleryItem } from './IGalleryItem';
import GalleryItem from './GalleryItem';

export default function Gallery({
  gallery, setDisplayCornerImage
}: { gallery: IGalleryItem[], setDisplayCornerImage(s: boolean): void }) {
  const [ currentImage, setCurrentImage ] = useState(gallery[0]);
  const [ displayPrev, setDisplayPrev ] = useState(false);
  const [ displayNext, setDisplayNext ] = useState(false);

  const [ lightboxOpen, setLightboxOpen ] = useState(false);

  useEffect(() => {
    setDisplayPrev(currentImage.idx !== 0);
    setDisplayNext(currentImage.idx !== gallery.length - 1);
  }, [ currentImage ]);

  function prevImage() {
    if (currentImage.idx === 0) {
      return;
    }

    setCurrentImage(gallery[currentImage.idx - 1]);
  }

  function nextImage() {
    if (currentImage.idx === gallery.length - 1) {
      return;
    }

    setCurrentImage(gallery[currentImage.idx + 1]);
  }

  function openLightbox(item) {
    setDisplayCornerImage(false);
    setCurrentImage(item);
    setLightboxOpen(true);
  }

  function closeLightbox() {
    setDisplayCornerImage(true);
    setLightboxOpen(false);
  }

  return (
    <>
      <div className={styles.container}>
        {gallery.map(item => <GalleryItem key={item.idx} item={item} openLightbox={openLightbox} />)}
      </div>

      {lightboxOpen && (
        <Lightbox
          image={currentImage}
          displayNext={displayNext}
          displayPrev={displayPrev}
          next={nextImage}
          prev={prevImage}
          close={closeLightbox}
        />
      )}
    </>
  );
}
