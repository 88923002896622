import { motion } from 'framer-motion';
import TheFooter from './the-footer/TheFooter';

export default function Layout(props) {
  const transitionVariants = {
    initial: {
      x: 60,
      opacity: 0,
      transition: {
        duration: 0.25
      }
    },
    out: {
      x: 60,
      opacity: 0,
      transition: {
        duration: 0.25
      }
    },
    in: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.25
      }
    }
  };

  return (
    <motion.div
      variants={transitionVariants}
      initial="initial"
      animate="in"
      exit="out"
    >
      <section className={`page mid ${props.large ? 'large': ''}`}>
        {props.children}

        <TheFooter />
      </section>
    </motion.div>
  );
}
