import styles from './HomeGallery.module.scss';

import { useState } from 'react';
import useConfig from '@/hooks/useConfig';

import { getGallerySize, getGalleryItems } from '@/lib/getGalleryItems';
import Gallery from '../gallery/Gallery';
import LinkButton from '../common/LinkButton';
import buttons from '../common/LinkButton.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeviantart } from '@fortawesome/free-brands-svg-icons';

export default function HomeGallery({ setDisplayCornerImage }) {
  const { getConfig, getSocial, t } = useConfig();
  const itemCount = getConfig('images.gallery.homepage.max-item-count');
  const maxRows = getConfig('images.gallery.homepage.max-rows');
  const total = getGallerySize();

  const [ count, setCount ] = useState(Math.min(itemCount, total));
  const [ overflow, setOverflow ] = useState(itemCount >= total);
  const [ gallery, setGallery ] = useState(getGalleryItems(count));

  function increment() {
    const newCount = count + itemCount;

    if (newCount >= total) {
      setOverflow(true);
      setCount(total);
    } else {
      setCount(newCount);
    }

    if (maxRows && newCount >= itemCount * maxRows) {
      setOverflow(true);
    }

    setGallery(getGalleryItems(newCount));
  }

  return (
    <>
      {gallery && <Gallery gallery={gallery} setDisplayCornerImage={setDisplayCornerImage} />}

      <div className={styles.buttons}>
        {!overflow && (
          <div className={styles.button}>
            <button onClick={increment}>{t('home.gallery.show-more')}</button>
          </div>
        )}

        <div className={styles.button}>
          <LinkButton href={getSocial('deviantart')} classList={[ buttons.deviantart ]}>
            <FontAwesomeIcon icon={faDeviantart} />
            {t('home.gallery.view-full')}
          </LinkButton>
        </div>
      </div>
    </>
  );
}
