import Icon from './Icon';
import styles from './SocialIcons.module.scss';

import useConfig from '@/hooks/useConfig';

import { ISocialIcon } from './ISocialIcon';

export default function SocialIcons() {
  const { getConfig } = useConfig();
  const socials: ISocialIcon[] = getConfig('socials');

  return (
    <div className={styles.iconWrapper}>
      {socials.map((social: ISocialIcon, idx: number) => {
        return <Icon id={social.id} value={social.value} key={idx} />;
      })}
    </div>
  );
}
