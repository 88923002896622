import Head from 'next/head';

export interface IMetaParams {
  title: string;
  description: string;
}

export default function Meta({ title, description }: IMetaParams) {
  return (
    <Head>
      <title>{title} - Raspberry Studios</title>

      <meta name="title" content={`${title} - Raspberry Studios`} />
      <meta name="description" content={description} />

      <meta property="og:title" content={`${title} - Raspberry Studios`} />
      <meta property="og:description" content={description} />
    </Head>
  );
}
