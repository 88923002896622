import SocialIcons from '../social-icons/SocialIcons';

export default function TheFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer>
        2016 - {currentYear} © Aurelia Charm/Zaria Rhodolite. All rights reserved. Do not trace,
        recolor, make bases from, edit, alter, and/or crop my artwork/watermark. Do not copy,
        sell, and/or redistribute any code of this website. MY LITTLE PONY and My Little Pony:
        Friendship is Magic are a registered trademark of Hasbro, Inc. &amp; DHX Media Ltd.

        <SocialIcons />
      </footer>
    </>
  );
}
