import Layout from '@/components/Layout';
import AboutBlock from '@/_content/blocks/home/About.mdx';
import HomeGallery from '@/components/home/HomeGallery';

import LinkButton from '@/components/common/LinkButton';
import buttons from '@/components/common/LinkButton.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';

import useConfig from '@/hooks/useConfig';
import Meta from '@/components/Meta';

export default function Index({ setDisplayCornerImage }) {
  const { getSocial, t } = useConfig();

  return (
    <Layout>
      <Meta
        title={t('common.site-name')}
        description={t('common.site-description')}
      />

      <AboutBlock />

      <section className="multiple-buttons">
        <LinkButton href={getSocial('facebook')} classList={[ buttons.facebook ]}>
          <FontAwesomeIcon icon={faFacebookF} />
          Check out my regular content
        </LinkButton>

        <LinkButton href={getSocial('youtube')} classList={[buttons.youtube]}>
          <FontAwesomeIcon icon={faYoutube} />
          Watch my animations
        </LinkButton>
      </section>

      <HomeGallery setDisplayCornerImage={setDisplayCornerImage} />
    </Layout>
  );
}
