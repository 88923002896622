import styles from './Icon.module.scss';

import { ISocialIcon } from './ISocialIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeviantart, faFacebookF, faInstagram, faTumblr, faTwitter, faYoutube, faPatreon } from '@fortawesome/free-brands-svg-icons';

export default function Icon({ id, value }: ISocialIcon) {
  const iconMap = {
    deviantart: faDeviantart,
    facebook: faFacebookF,
    instagram: faInstagram,
    tumblr: faTumblr,
    twitter: faTwitter,
    youtube: faYoutube,
    patreon: faPatreon
  };

  return (
    <a
      href={value}
      target="_blank"
      className={[ styles.icon, styles[id] ].join(' ')}
      rel="noreferrer noopener"
    >
      <FontAwesomeIcon icon={iconMap[id]} />
    </a>
  );
}
