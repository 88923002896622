import { ReactNode } from 'react';
import styles from './LinkButton.module.scss';

export interface ILinkButton {
  href: string;
  children: ReactNode;
  classList?: string[];
}

export default function LinkButton(props: ILinkButton) {
  const className = props.classList && props.classList.length
    ? [ styles.linkButton, ...props.classList ].join(' ')
    : styles.linkButton;

  return (
    <a href={props.href} target="_blank" rel="noreferrer noopener">
      <button className={className}>
        {props.children}
      </button>
    </a>
  );
}
