import { IGalleryItem } from '@/components/gallery/IGalleryItem';
import { images } from '@/_content/gallery.json';
import getImageURLs from './getImageURLs';

export function getGalleryItems(limit: number = images.length): IGalleryItem[] {
  return images
    .slice(0)
    .reverse()
    .slice(0, limit)
    .map((item, idx) => {
      return {
        idx,
        title: item.title,
        ...getImageURLs(item.filename, true)
      };
    });
}

export function getGallerySize(): number {
  return images.length;
}
