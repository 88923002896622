function buildUrl(filename: string, prefix: string): string {
  const key = `${prefix}/${filename}`;
  return `https://firebasestorage.googleapis.com/v0/b/raspberry-studios.appspot.com/o/${encodeURIComponent(key)}?alt=media`;
}

export interface ImageURLs {
  full: string;
  thumb?: string;
}

export default function getImageURLs(filename: string, hasThumbnail: boolean = false): ImageURLs {
  const urls: ImageURLs = {
    full: buildUrl(filename, 'full')
  };

  if (hasThumbnail) {
    urls.thumb = buildUrl(filename, 'thumb');
  }

  return urls;
}
