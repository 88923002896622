
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Welcome!`}</h1>
    <center>
      <p>{`I'm Zaria from Raspberry Studios, better known on my social media platforms as
Aurelia.`}</p>
      <p>{`I am a self taught digital artist and have been selling custom commissions to
customers worldwide since 2016. My work is mainly inspired by cartoons and
animation and is a labour of love, I find it incredibly difficult to go a day
without creating art whether it be for myself or my customers!`}</p>
      <p>{`For more exclusive content including current works in progress, commission discounts, exclusive rewards and up-and-coming projects feel free to
`}<a parentName="p" {...{
          "href": "https://www.patreon.com/raspberrystudios"
        }}>{`check out my Patreon`}</a>{`!`}</p>
    </center>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;