import { IGalleryItem } from './IGalleryItem';
import styles from './Gallery.module.scss';
import { useState } from 'react';

export default function GalleryItem({
  item, openLightbox
}: { item: IGalleryItem, openLightbox(item: IGalleryItem): void }) {
  const [ loaded, setLoaded ] = useState(false);

  return (
    <div className={styles.item} onClick={() => openLightbox(item)}>
      <img
        alt={item.title}
        src={item.thumb}
        onLoad={() => setLoaded(true)}
        className={ loaded ? styles.loaded : '' }
      />
    </div>
  );
}
